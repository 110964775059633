/* Smooth progress bar animation */
@keyframes progress {
  0% {
    width: 0%;
    opacity: 0.8;
  }
  30% {
    width: 60%;
    opacity: 0.8;
  }
  80% {
    width: 90%;
    opacity: 0.8;
  }
  100% {
    width: 98%;
    opacity: 0.2;
  }
}

.animate-progress {
  animation: progress 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

/* Smooth spinning animation */
@keyframes smooth-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-smooth-spin {
  animation: smooth-spin 1.5s linear infinite;
}

/* Smooth pulse animation */
@keyframes smooth-pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.5;
  }
}

.animate-smooth-pulse {
  animation: smooth-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* Smooth bounce animation */
@keyframes smooth-bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}

.animate-smooth-bounce {
  animation: smooth-bounce 1s cubic-bezier(0.45, 0, 0.55, 1) infinite;
}

/* Fade in animation */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fade-in 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
