@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './styles/animations.css';

body { 
  background:#000300;
  max-width: 100%;
  overflow-x: hidden;
  touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.bk-red{
  background-color:#ec3232
}

.red-theme {
  color : #ec3232
}

.login-container { 
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 200px;
  display :flex; 
  flex-direction: column;
  width: 600px;
  align-items: center;
  background:#000300;
  padding-bottom: 30px;
}

.login-head {
  margin-top: 30px;
  font: bold; 
  color: white;
  font-size: 40px;
  font-weight: 600;
}


.underline { 
  width:61px ; 
  height: 6px; 
  background: white ;
  border-radius: 9px ; 
  display :flex; 
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.text-login-cred{
  color: white ; 

}

.inputs { 
  display: flex; 
  align-items: center; 
  margin:auto ; 
  width: 480px; 
  height: 80px;
  background:#eaeaea;
  border-radius: 6px ;
  margin-bottom: 20px;
  padding-left: 20px;
}

.input-box{
  height: 50px;
  width: 400px; 
  background: transparent; 
  border: none;
  outline: none;
  color: #797979;
  font-size: 16px;
  padding-left: 10px;
}

.forgot-password{
  padding-left: 10px ;
  /* margin-top: 27px; */
  color: #ffffff;
  font-size: 16px;
}

.forgot-password span {
  color: #ec3232;
  font: bold; 
  /* gap: 20px;  */
  /* margin: 50px auto; */
  font-size: 20px;
  padding-right: 240px;

}

.submit-container{
  display: flex;
  gap: 30px; 
  margin-top:20px auto; 
}

.submit {
  display: flex; 
  justify-content: center;
  align-items: center;
  width: 220px; 
  height:59px; 
  color:#ffff; 
  background-color: #be2626;
  border-radius: 10px;
  font-size: 19px;
  font-weight: 700;
  cursor: pointer;

}

.gray{
  background-color: #EAEAEA;
  color: #676767;
}

.google{
  width: 300px; 
  height:59px; 
  border-radius: 10px;
  font-size: 17px;
  font-weight: 500;
  color:#ffff; 
}

.or-tag{
  width:150px ; 
  height: 1px; 
  background: white ;
  border-radius: 0px ; 
  display :flex;
  margin: 20px;
}


.spline-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  
}
.loader { 
  position: fixed; /* Sticks the loader to the viewport */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f1013; /* Optional: semi-transparent background */
  z-index: 9999; /* Ensure it's on top of everything */
}


.autocomplete-suggestions {
  background-color: #333;
  border: 1px solid #555;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.autocomplete-suggestions li {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-suggestions li:hover {
  background-color: #555;
}


.search-results {
  font-family: Arial, sans-serif;
  color: white;
}

.course-list {
  list-style-type: none;
  padding: 0;
}

.course-item {
  border: 1px solid #444;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #222;
  border-radius: 4px;
}

.course-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.course-info {
  display: flex;
  gap: 15px;
}

.course-code,
.course-title,
.course-credits,
.course-status {
  color: white;
}

.expand-toggle {
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
}

.course-details {
  margin-top: 10px;
  color: white;
}

.section-item {
  border-top: 1px solid #666;
  padding-top: 10px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: bold;
}

.section-status {
  padding: 2px 8px;
  border-radius: 3px;
  color: #fff;
}

.section-status.open {
  background-color: #4caf50;
}

.section-status.closed {
  background-color: #f44336;
}

.meeting-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.meeting-table th,
.meeting-table td {
  border: 1px solid #666;
  padding: 8px;
  text-align: left;
}

.meeting-table th {
  background-color: #333;
  color: white;
}

.meeting-table td {
  color: white;
}



/* Basic styles */
.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Mobile menu toggle button */
button.md\\:hidden {
  display: block;
}

/* Mobile view */
@media (max-width: 768px) {
  .navbar ul {
    display: none; /* Hide menu by default on mobile */
    flex-direction: column;
    background-color: #333;
    width: 100%;
    position: absolute;
    top: 60px; /* Adjust based on navbar height */
    left: 0;
  }

  .navbar ul.flex {
    display: flex;
  }

  /* Center items in mobile view */
  .navbar ul li {
    text-align: center;
    padding: 10px;
  }
}


.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}